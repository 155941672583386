import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { Review } from '@jelly/templates'

const DEFAULT_CHANNEL = {
	primary: {
		name: 'Business Basics',
		slug: 'business-basics',
	},
	sub: {
		name: 'Starting a Business',
		slug: 'starting-a-business',
	},
}

const Index = ({ data: { props, related }, pageContext: { author, canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical
	props.author = author
	props.author.slug = author.user?.slug || author.slug
	const authorImagePath = author.user?.imagePath.includes('undefined') ? null : author.user?.imagePath
	props.author.thumbnail = author.thumbnail || author.avatar || authorImagePath
	//Do we want this in graphql?
	props.category = !!props.category
		? props.category
		: {
				path: '/reviews/',
				title: 'Reviews at business.com',
				thumbnail: {
					path:
						'https://www.business.com/images/rz/5daf54955a215ee92d8b457d/Marketing-data-analytics_NicoElNino_BDC-cropped.png',
					isBlurred: false,
					caption: 'Getty Images',
				},
		  }
	//alias for now, update component
	props.category.url = props.category.path
	props.channels = !!props.channels ? props.channels : DEFAULT_CHANNEL
	props.breadcrumbs = !!props.breadcrumbs ? props.breadcrumbs : props.channels

	return <Review {...props} {...related} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query review($slug: String!, $preview: Boolean) {
		related: review(slug: $slug, preview: $preview, cache: true) {
			relatedArticles {
				canonical
				dek
				lastModified
				slug
				thumbnail
				title
			}
			relatedBestPicks {
				canonical
				title
				thumbnail
			}
		}
		# We always need to return props to ve injected on the component
		props: review(slug: $slug, preview: $preview, cache: false) {
			adsense
			adsenseMobile
			byLine
			canonical
			category {
				slug
				thumbnail {
					caption
					isBlurred
					path
				}
				title
				path
			}
			breadcrumbs {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			channels {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			lastModified: displayModified
			docType
			guid
			meta {
				robots
				description
				title
			}
			pricing {
				isCapped
				affiliate {
					cta
					link
					name
				}
				isFeatured
				primary
				selected
				widget {
					cta
					id
					isExclusive
				}
				widgetId
			}
			published
			screenshots {
				caption
				order
				url
			}
			review {
				fullReview
				stream
				subtitle
				title
				verdict
			}
			showAuthorBio
			slug
			structuredData
			subtitle
			thumbnail {
				caption
				isBlurred
				path
			}
			title
			url
		}
	}
`
